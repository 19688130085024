import HomeCard from "@/components/home/HomeCard";
import Head from "next/head";
import Image from "next/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHandHeart,
    faWater,
    faHandsHelping,
    faHome,
    faQuestion,
} from "@fortawesome/pro-light-svg-icons";
import { faMedal, faChild } from "@fortawesome/pro-solid-svg-icons";

interface Props {
    showVip: boolean;
    showEgroupLead: boolean;
    showEgroupJoin: boolean;
    showSalvation: boolean;
    showBaptism: boolean;
    showEteams: boolean;
    showEkidz: boolean;
    showWatchParty: boolean;
    showPrayer: boolean;
    vipPosClassName: string;
    egroupLeadPosClassName: string;
    egroupJoinPosClassName: string;
    salvationPosClassName: string;
    baptismPosClassName: string;
    eteamsPosClassName: string;
    ekidzPosClassName: string;
    watchPartyPosClassName: string;
    prayerPosClassName: string;
}

function HomePage(props: Props) {
    return (
        <>
            <Head>
                <title key="title">Elevation Church Connection Center</title>
            </Head>

            <div className="home flex flex-justify-center flex-align-center extra-high-minus-menu">
                <div className="column-xs-12 container container-bigger m-y-spacer-xl cards-wrapper">
                    <div>
                        <h1 className="no-top-margin no-bottom-margin">
                            Elevation Connect
                        </h1>
                        <p className="half-here icon-xl">
                            Sign up to get involved at Elevation Church.
                        </p>
                    </div>

                    <div className="content">
                        <div className="full-width home-grid">
                            {props.showVip ? (
                                <HomeCard
                                    cardlink={
                                        process.env
                                            .NEXT_PUBLIC_FIRST_TIME_LINK_DIRECT ??
                                        "/vip"
                                    }
                                    externalLink={
                                        !!process.env
                                            .NEXT_PUBLIC_FIRST_TIME_LINK_DIRECT
                                    }
                                    imgdiv="card-img-div"
                                    imgURL="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/home/VIP-Online-CC-cover-image"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faMedal}
                                            className="icon-xl ec-text-white"
                                        />
                                    }
                                    iconcolor="icon-eteam"
                                    title="First Time?"
                                    className={`vip ${props.vipPosClassName}`}
                                    description="We’d love to connect with you!"
                                    showMobile={true}
                                    showOverlay={false}
                                    id="cc-connect-first-time"
                                />
                            ) : null}

                            {props.showEgroupLead ? (
                                <HomeCard
                                    cardlink="/egroups/lead"
                                    imgdiv="card-img-div card-image"
                                    imgURL="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/home/eGroups_Lead"
                                    icon={
                                        <Image
                                            className="egroup-icon"
                                            src="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/home/icon_leader.png"
                                            alt="Lead an eGroup Icon"
                                            layout="intrinsic"
                                            width={32.96}
                                            height={23.06}
                                        />
                                    }
                                    iconcolor="icon-egroup"
                                    title="Lead an eGroup"
                                    description="Sign up for leader orientation."
                                    className={`egroup-lead  ${props.egroupLeadPosClassName}`}
                                    showMobile={true}
                                    showOverlay={false}
                                    id="cc-connect-lead-an-egroup"
                                />
                            ) : null}

                            {props.showEgroupJoin ? (
                                <HomeCard
                                    cardlink="/egroups"
                                    imgdiv="card-img-div card-image"
                                    imgURL="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/home/Online-eGroup-homepage-card"
                                    icon={
                                        <Image
                                            className="egroup-icon"
                                            src="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/home/icon_join.png"
                                            alt="Join eGroup Icon"
                                            layout="intrinsic"
                                            width={32.96}
                                            height={23.06}
                                        />
                                    }
                                    iconcolor="icon-egroup"
                                    title="Join an eGroup"
                                    description="Find a small group near you."
                                    className={`egroup-join  ${props.egroupJoinPosClassName}`}
                                    showMobile={true}
                                    showOverlay={false}
                                    id="cc-connect-join-an-egroup"
                                />
                            ) : null}

                            {props.showSalvation ? (
                                <HomeCard
                                    cardlink={
                                        process.env
                                            .NEXT_PUBLIC_SALVATION_LINK_DIRECT
                                            ? process.env
                                                  .NEXT_PUBLIC_SALVATION_LINK_DIRECT
                                            : "/salvation"
                                    }
                                    imgdiv="card-img-div card-image"
                                    imgURL="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/home/salvations_connection_center"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faHandHeart}
                                            className="icon-xl ec-text-white"
                                        />
                                    }
                                    externalLink={
                                        !!process.env
                                            .NEXT_PUBLIC_SALVATION_LINK_DIRECT
                                    }
                                    iconcolor="icon-salvation"
                                    title="Salvation"
                                    className={`salvation  ${props.salvationPosClassName}`}
                                    description="We’d love to celebrate your decision."
                                    showMobile={true}
                                    showOverlay={false}
                                    id="cc-connect-salvation"
                                />
                            ) : null}

                            {props.showBaptism ? (
                                <HomeCard
                                    cardlink={
                                        process.env
                                            .NEXT_PUBLIC_BAPTISM_LINK_DIRECT
                                            ? process.env
                                                  .NEXT_PUBLIC_BAPTISM_LINK_DIRECT
                                            : "/baptism"
                                    }
                                    imgdiv="card-img-div card-image"
                                    externalLink={
                                        !!process.env
                                            .NEXT_PUBLIC_BAPTISM_LINK_DIRECT
                                    }
                                    imgURL="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/cards/Baptism"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faWater}
                                            className="icon-xl ec-text-white"
                                        />
                                    }
                                    iconcolor="icon-baptism"
                                    title="Baptism"
                                    className={`baptism  ${props.baptismPosClassName}`}
                                    description="Sign up to get baptized."
                                    showMobile={true}
                                    showOverlay={false}
                                    id="cc-connect-baptism"
                                />
                            ) : null}

                            {props.showEteams ? (
                                <HomeCard
                                    cardlink={
                                        process.env
                                            .NEXT_PUBLIC_ETEAMS_LINK_DIRECT
                                            ? process.env
                                                  .NEXT_PUBLIC_ETEAMS_LINK_DIRECT
                                            : "/eteams"
                                    }
                                    imgdiv="card-img-div card-image"
                                    imgURL="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/cards/eTeams"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faHandsHelping}
                                            className="icon-xl ec-text-white"
                                        />
                                    }
                                    externalLink={
                                        !!process.env
                                            .NEXT_PUBLIC_ETEAMS_LINK_DIRECT
                                    }
                                    iconcolor="icon-eteam"
                                    title="eTeams"
                                    className={`eTeams  ${props.eteamsPosClassName}`}
                                    description="Sign up for e101, volunteer orientation."
                                    showMobile={true}
                                    showOverlay={false}
                                    id="cc-connect-eteams"
                                />
                            ) : null}

                            {props.showEkidz ? (
                                <HomeCard
                                    cardlink={
                                        process.env
                                            .NEXT_PUBLIC_EKIDZ_CC_FORM_URL_DIRECT
                                            ? process.env
                                                  .NEXT_PUBLIC_EKIDZ_CC_FORM_URL_DIRECT
                                            : "/ekidz"
                                    }
                                    externalLink={
                                        !!process.env
                                            .NEXT_PUBLIC_EKIDZ_CC_FORM_URL_DIRECT
                                    }
                                    imgdiv="card-img-div card-image"
                                    imgURL="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/home/eKidz-Online-CC-cover-image"
                                    objectPosition="top"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faChild}
                                            className="icon-xl ec-text-white"
                                        />
                                    }
                                    iconcolor="icon-salvation"
                                    title="Connect Your Kids"
                                    className={`salvation-ekidz ${props.ekidzPosClassName}`}
                                    description="Let us know if it's your child's first time at Elevation."
                                    showMobile={true}
                                    showOverlay={false}
                                    id="cc-connect-ekidz"
                                />
                            ) : null}

                            {props.showWatchParty ? (
                                <HomeCard
                                    cardlink={
                                        process.env
                                            .NEXT_PUBLIC_WATCH_PARTIES_LINK ||
                                        ""
                                    }
                                    externalLink={true}
                                    imgdiv="watch-parties-img-div card-image"
                                    imgURL="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/home/watchParties-CC-cover-image"
                                    objectPosition="top"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faHome}
                                            className="icon-xl ec-text-white"
                                        />
                                    }
                                    iconcolor="icon-watchparty"
                                    title="Watch Party"
                                    className={`watchParty ${props.watchPartyPosClassName}`}
                                    description="Sign up to lead or join a watch party in your area."
                                    showMobile={true}
                                    showOverlay={false}
                                    id="cc-connect-watch-party"
                                />
                            ) : null}

                            {props.showPrayer ? (
                                <HomeCard
                                    cardlink={
                                        process.env.NEXT_PUBLIC_PRAYERS_LINK ||
                                        ""
                                    }
                                    externalLink={true}
                                    imgdiv="need-prayer-img-div rel-pos card-image"
                                    imgURL="https://dv9pmwasxvawj.cloudfront.net/images/connection_center/cards/NeedPrayer"
                                    objectPosition="top"
                                    icon={
                                        <FontAwesomeIcon
                                            icon={faQuestion}
                                            className="icon-xl ec-text-white"
                                        />
                                    }
                                    iconcolor="icon-salvation"
                                    title="Need Prayer?"
                                    className={`questions ${props.prayerPosClassName}`}
                                    description="We’re happy to help."
                                    showMobile={true}
                                    showOverlay={false}
                                    id="cc-connect-prayer"
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export const getStaticProps = async () => {
    let currentPos = 1;

    const cardPosClassname = {
        1: "row1Col1",
        2: "row1Col2 smaller-card",
        3: "row1Col3 smaller-card",
        4: "row2Col2 smaller-card",
        5: "row2Col3 smaller-card",
        6: "row3Col1 smaller-card",
        7: "row3Col2 smaller-card",
        8: "row3Col3 smaller-card",
        9: "row4Col2 smaller-card",
    };

    const cardPos = {
        vip: -1,
        egroupLead: -1,
        egroupJoin: -1,
        salvation: -1,
        baptism: -1,
        eteams: -1,
        ekidz: -1,
        watchParty: -1,
        prayer: -1,
    };

    if (process.env.DISABLE_VIP !== "1") {
        cardPos.vip = currentPos;
        currentPos++;
    }

    if (process.env.DISABLE_EGROUPS !== "1") {
        cardPos.egroupJoin = currentPos;
        currentPos++;
    }

    if (process.env.DISABLE_EKIDZ !== "1") {
        cardPos.ekidz = currentPos;
        currentPos++;
    }

    if (process.env.DISABLE_SALVATION !== "1") {
        cardPos.salvation = currentPos;
        currentPos++;
    }

    if (process.env.DISABLE_WATCH_PARTY !== "1") {
        cardPos.watchParty = currentPos;
        currentPos++;
    }

    if (process.env.DISABLE_EGROUPS_LEAD !== "1") {
        cardPos.egroupLead = currentPos;
        currentPos++;
    }

    if (process.env.DISABLE_BAPTISM !== "1") {
        cardPos.baptism = currentPos;
        currentPos++;
    }

    if (process.env.DISABLE_PRAYER !== "1") {
        cardPos.prayer = currentPos;
        currentPos++;
    }

    if (process.env.DISABLE_ETEAMS !== "1") {
        cardPos.eteams = currentPos;
        currentPos++;
    }

    return {
        props: {
            showVip: process.env.DISABLE_VIP !== "1",
            showEgroupLead: process.env.DISABLE_EGROUPS_LEAD !== "1",
            showEgroupJoin: process.env.DISABLE_EGROUPS !== "1",
            showSalvation: process.env.DISABLE_SALVATION !== "1",
            showBaptism: process.env.DISABLE_BAPTISM !== "1",
            showEteams: process.env.DISABLE_ETEAMS !== "1",
            showEkidz: process.env.DISABLE_EKIDZ !== "1",
            showWatchParty: process.env.DISABLE_WATCH_PARTY !== "1",
            showPrayer: process.env.DISABLE_PRAYER !== "1",

            vipPosClassName: cardPosClassname[cardPos.vip] ?? "",
            egroupLeadPosClassName: cardPosClassname[cardPos.egroupLead] ?? "",

            egroupJoinPosClassName: cardPosClassname[cardPos.egroupJoin] ?? "",
            salvationPosClassName: cardPosClassname[cardPos.salvation] ?? "",
            baptismPosClassName: cardPosClassname[cardPos.baptism] ?? "",
            eteamsPosClassName: cardPosClassname[cardPos.eteams] ?? "",
            ekidzPosClassName: cardPosClassname[cardPos.ekidz] ?? "",
            watchPartyPosClassName: cardPosClassname[cardPos.watchParty] ?? "",
            prayerPosClassName: cardPosClassname[cardPos.prayer] ?? "",
        },
    };
};

export default HomePage;
