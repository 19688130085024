import { FC } from "react";
import { Props } from "./types";
import Image from "next/image";
import { shimmer, toBase64 } from "src/js/constants";

// Card Component
/**
 * This component is used for the rendering a card styled container
 */
// let helpers = new Helpers();

// const webpSupported = helpers.webpSupported();

const HomeCard: FC<Props> = (props) => {
    const imgURL = `${props.imgURL}.jpg`;

    const content = (
        <>
            <div
                className={`rel-pos ${props.imgdiv} full-width not-clickable ${
                    props.showMobile ? "" : "mobile-hide"
                } card-image`}
            >
                <Image
                    layout="fill"
                    className="img-cover ec-top-border-radius not-clickable"
                    objectFit={"cover"}
                    objectPosition={
                        props.objectPosition ? props.objectPosition : "center"
                    }
                    src={imgURL}
                    alt={props.imgdiv}
                    placeholder="blur"
                    blurDataURL={`data:image/svg+xml;base64,${toBase64(
                        shimmer(440, 200)
                    )}`}
                />
            </div>

            <div
                className={`lower-content flex flex-align-center flex-justify-between more-forward not-clickable ${
                    props.addBackgroundToButton ? "" : "p-x-spacer-md"
                }`}
            >
                <div className="flex flex-align-center not-clickable">
                    {props.icon ? (
                        <div
                            className={`${
                                props.iconcolor || ""
                            } icon-div rel-pos font-size-24`}
                        >
                            {props.icon}
                        </div>
                    ) : null}

                    <div className="text-content card-smallPadding not-clickable">
                        {props.title && (
                            <h3 className="no-top-margin no-bottom-margin not-clickable">
                                {props.title}
                            </h3>
                        )}

                        {props.description && (
                            <p className="half-here icon-sm no-bottom-margin regular not-clickable font-size-16">
                                {props.description}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {props.showOverlay ? (
                <div className="full-width full-height uptop abs-pos pull-forward light-overlay not-clickable"></div>
            ) : (
                ""
            )}
        </>
    );

    const handleClick = () => {
        if (props.onCardClick) {
            props.onCardClick();
        } else {
            return;
        }
    };

    if (props.onCardClick) {
        return (
            <button
                style={
                    props.addBackgroundToButton
                        ? { backgroundImage: `url('${imgURL ? imgURL : ""}')` }
                        : {}
                }
                onClick={handleClick}
                id={props.id ? props.id : ""}
                className={`${props.position || ""} ${
                    props.className || ""
                } flex flex-column full-width no-padding no-border egroup-card ec-box-shadow ec-border-radius-16 ec-text-dark hover-grow mobile-full-width m-b-spacer-lg ec-background-white rel-pos ${
                    props.addBackgroundToButton ? "text-center" : "text-left"
                }`}
            >
                {content}
            </button>
        );
    } else {
        return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a
                target={props.externalLink ? "_blank" : ""}
                rel={props.externalLink ? "noreferrer" : ""}
                id={props.id ? props.id : ""}
                href={props.cardlink}
                className={`${props.position || ""} ${
                    props.className || ""
                } flex flex-column egroup-card ec-box-shadow ec-border-radius-16 ec-text-dark hover-grow mobile-full-width ec-background-white rel-pos`}
            >
                {content}
            </a>
        );
    }
};

export default HomeCard;
